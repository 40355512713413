import React, { useState, useEffect } from "react";

const Worldmap = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const locations = [
    { name: "Canada", top: "27%", left: "29%" },
    { name: "United States", top: "40%", left: "20%" },
    { name: "Europe", top: "30%", left: "50%" },
    { name: "Russia", top: "25%", left: "62%" },
    { name: "India", top: "48%", left: "65%" },
    { name: "Australia", top: "70%", left: "86%" },
    { name: "Africa", top: "53%", left: "54%" },
  ];

  // Handle window resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openModal = (location) => {
    setSelectedLocation(location);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLocation(null);
  };

  const marginTop = windowWidth <= 768 ? "0px" : "-80px";

  return (
    <div
      className="worldmap-container"
      style={{
        padding: "20px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {/* Text Section */}
      <div
        className="text-section text-center px-3"
        style={{
          marginBottom: "0",
        }}
      >
        <h5
          className="text-uppercase"
          style={{
            color: "rgb(64, 29, 186)",
            letterSpacing: "1px",
            fontSize: "14px",
            marginBottom: "10px",
          }}
        >
          Our Global Reach
        </h5>

        <div className="row text-center">
          <div className="col-12 col-md-8 mx-auto">
            <h2>Empowering Businesses Worldwide</h2>
            <p>
              At Odidor, we're passionate about helping businesses thrive in an
              ever-evolving digital world. We offer innovative solutions in app
              development, web design, software development, digital marketing,
              and enterprise services. Whether you’re in Canada, the United
              States, Europe, Russia, India, Australia, or Africa, we’re here to
              provide you with the tools and support you need to grow and
              succeed. Let’s make the future together.
            </p>
          </div>
        </div>
      </div>

      {/* Map Section */}
      <div
        className="map-section position-relative"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 10px",
          position: "relative",
          marginTop: marginTop,
        }}
      >
        {/* Map Image */}
        <img
          src="/assets/img/global-map.png"
          alt="World Map"
          className="img-fluid"
          style={{
            width: "100%",
            maxWidth: "1200px",
          }}
        />

        {/* Markers */}
        {locations.map((location) => (
          <div
            key={location.name}
            onClick={() => openModal(location.name)}
            style={{
              position: "absolute",
              top: location.top,
              left: location.left,
              transform: "translate(-50%, -50%)",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "12px",
              cursor: "pointer",
              fontSize: "12px",
              fontWeight: "bold",
              backgroundColor: "rgb(64, 29, 186)",
              transition: "background-color 0.3s ease, border-radius 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#440f99";
              e.target.style.borderRadius = "20px";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "rgb(64, 29, 186)";
              e.target.style.borderRadius = "12px";
            }}
          >
            {location.name}
          </div>
        ))}
      </div>

     {/* Modal */}
{isModalOpen && (
  <div
    className="modal-overlay"
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 999,
      backdropFilter: "blur(10px)",
      transition: "all 0.3s ease",
    }}
    onClick={closeModal}
  >
    <div
      className="modal-content"
      style={{
        backgroundColor: "#fff",
        padding: "40px 30px",
        borderRadius: "16px",
        maxWidth: "90%", // Adapt to smaller screens
        width: "600px",
        textAlign: "center",
        position: "relative",
        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
        transform: "scale(1)",
        transition: "transform 0.3s ease-in-out",
        animation: "fadeInUp 0.5s ease-out",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {/* Progress Bar */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "5px",
          background: "linear-gradient(90deg, #402dbA 0%, #825bf0 100%)",
          animation: "progressBar 3s ease-in-out",
        }}
      ></div>

      {/* Optional Image */}
      <img
        src="https://via.placeholder.com/600x150"
        alt="Visual"
        style={{
          width: "100%",
          borderRadius: "12px",
          marginBottom: "20px",
        }}
      />

      {/* Close Button */}
      <button
        onClick={closeModal}
        style={{
          position: "absolute",
          top: "15px",
          right: "15px",
          backgroundColor: "transparent",
          color: "rgb(64, 29, 186)",
          border: "2px solid rgb(64, 29, 186)",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          fontSize: "18px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          transition: "all 0.3s ease",
        }}
        onMouseOver={(e) => {
          e.target.style.backgroundColor = "rgb(64, 29, 186)";
          e.target.style.color = "#fff";
        }}
        onMouseOut={(e) => {
          e.target.style.backgroundColor = "transparent";
          e.target.style.color = "rgb(64, 29, 186)";
        }}
      >
        <i className="fa fa-times" aria-hidden="true"></i>
      </button>

      {/* Location Title */}
      <h3
        style={{
          color: "rgb(64, 29, 186)",
          marginBottom: "20px",
          fontSize: "28px",
          fontWeight: "700",
          letterSpacing: "1.5px",
          textTransform: "uppercase",
        }}
      >
        {selectedLocation}
      </h3>

      {/* Location Description */}
      <p
        style={{
          color: "#555",
          fontSize: "15px",
          lineHeight: "1.8",
          marginBottom: "30px",
          fontWeight: "400",
          textAlign: "justify",
        }}
      >
        <strong style={{ color: "rgb(64, 29, 186)" }}>Odidor</strong> is a
        leading software company providing innovative digital solutions for
        global markets. With a strong foundation in British Columbia, we
        specialize in delivering cutting-edge software tailored to meet the
        unique needs of businesses in{" "}
        <strong style={{ color: "rgb(64, 29, 186)" }}>
          {selectedLocation}
        </strong>
        . Our commitment is to deliver the highest quality software products
        and services, empowering businesses worldwide to succeed in the digital
        era.
      </p>

     

      {/* Explore More Button */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          width: "100%",
        }}
      >
        <a
          href="/"
          title="maps"
          className="m-btn m-btn-border text-light rounded-pill"
          style={{
            background:
              "linear-gradient(135deg, rgb(64, 29, 186) 0%, rgb(130, 91, 240) 100%)",
            padding: "14px 32px",
            fontSize: "18px",
            fontWeight: "bold",
            textDecoration: "none",
            borderRadius: "50px",
            boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.15)",
            transition: "all 0.3s ease",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "54px",
            lineHeight: "normal",
            textAlign: "center",
          }}
          onMouseEnter={(e) =>
            (e.target.style.boxShadow = "0 0 15px rgb(130, 91, 240)")
          }
          onMouseLeave={(e) =>
            (e.target.style.boxShadow =
              "0px 12px 24px rgba(0, 0, 0, 0.15)")
          }
        >
          Explore More{" "}
          <i
            className="fa fa-arrow-right"
            style={{ marginLeft: "10px" }}
          ></i>
        </a>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default Worldmap;
