import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import MainBanner from '../components/MainBanner'
import Newsletter from '../components/Newsletter'
import ServiceCard from '../components/ServiceCard'
import { Helmet } from 'react-helmet'
import Partners from '../components/Partners'
import FAQAccordion from '../components/FAQAccordion'
import Worldmap from '../components/Worldmap'

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://odidor.co" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Website development agency </title>
                <meta name="description" content="Discover Odidor, your premier digital marketing company in Greater Vancouver. Elevate your online presence with expert web design and tailored strategies for SEO success. Reach your audience effectively with Odidor's innovative solutions." />
                <meta name="keywords" content="Web design Vancouver, website development, SEO services Vancouver, SEO, search engine optimization, Vancouver digital agency, online branding, Vancouver website design, content strategy,Vancouver e-commerce solutions, Vancouver internet marketing, Burnaby website builder, Coquitlum website agency, web analytics, Vancouver web design, website optimization." />
                <meta property="og:title" content="Website development agency " />
                <meta property="og:description"
                    content="Discover Odidor, your premier digital marketing company in Greater Vancouver. Elevate your online presence with expert web design and tailored strategies for SEO success. Reach your audience effectively with Odidor's innovative solutions." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://odidor.co" />
                <meta property="og:image" content="https://odidor.co/assets/img/services/AppDev.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Website development agency " />
                <meta name="twitter:description"
                    content="Discover Odidor, your premier digital marketing company in Greater Vancouver. Elevate your online presence with expert web design and tailored strategies for SEO success. Reach your audience effectively with Odidor's innovative solutions." />
                <meta name="twitter:image" content="https://odidor.co/assets/img/services/AppDev.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Website development agency " />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Discover Odidor, your premier digital marketing company in Greater Vancouver. Elevate your online presence with expert web design and tailored strategies for SEO success. Reach your audience effectively with Odidor's innovative solutions." />
                <meta property="og:image" content="https://odidor.co/assets/img/services/AppDev.jpg" />
                <meta property="og:url" content="https://odidor.co" />
            </Helmet>
            <main>
                <Header />
                <MainBanner />
                <ServiceCard />
                <Partners />
                <Worldmap/>
                <Newsletter />
                <FAQAccordion />
                <Footer />
            </main>
        </>
    )
}

export default Home